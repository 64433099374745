.App {
    display: flex;
    align-items: center;    
    height: 95vh;    
    position: relative;    
}

.prueba {    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 40%;
    left: 18%;     
}

.image1 {
    max-width:30%;
    max-height: 100px;
    animation: moveInRight 2s;  
}

.image2 {    
    max-width:18%;
    max-height: 100px;
    animation: moveInLeft 2s; 
}

.line {
    width: 500px;
    height: 1px;
    background-color: #c0c0c0;
    animation: moveInLeft 2s;
}

.subtitle {
    color: red;
    letter-spacing: 6px;    
    font-weight: bold;
    font-size: 12px;
    margin-top: 20px;
    animation: showSubtitle 8s;
}

@keyframes moveInLeft {
    0% {
        opacity: 0;
        transform: translateX(-100px);
    }

    100% {
        opacity: 1;
        transform: translate(0);
    }
}

@keyframes moveInRight {
    0% {
        opacity: 0;
        transform: translateX(100px);
    }    

    100% {
        opacity: 1;
        transform: translate(0);
    }
}

@keyframes showSubtitle {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}